<template>

  <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  <router-view v-slot="{ Component }">
    <transition mode="out-in" name="route-fade">
      <component :key="$route.path" :is="Component" />
    </transition>
  </router-view>
  <!-- <router-view/> -->

  <TheHeader />
  <TheFooter />
</template>
<script>
// @ is an alias to /src

import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue"

export default {
  name: "HomeView",
  components: {
    TheHeader,
    TheFooter,

  },



};
</script>
<style>
body {
  margin: 0;

}
#app{
  position: relative;
}

::-webkit-scrollbar {
  width: 0;
}

nav {
  padding: 30px;
}

#app a {
  font-weight: 400;
  color: black;
  font-size: 1.3vw;
  text-decoration: none;
  opacity: 0.5;

  font-family: 'Graphik';
}

#app a:hover {
  opacity: 0.8;

}

#app a.router-link-exact-active {
  opacity: 1;
}

.route-fade-enter-active {
  transition: all 0.5s ease-out;
}

.route-fade-leave-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}

.route-fade-enter-from,
.route-fade-leave-to {

  opacity: 0;
}

@media (max-width: 600px) {
  #app a {
  font-weight: 400;
  color: black;
  font-size: 16px;
  text-decoration: none;
  opacity: 0.8;

  font-family: 'Graphik';
}
}
</style>
