import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BelorechenskProject from '../views/Projects/BelorechenskProject.vue'
import KrasnodarProject from '../views/Projects/KrasnodarProject.vue'
import BelorechenskOneProject from '../views/Projects/BelorechenskOneProject.vue'
import BelorechenskTwoProject from '../views/Projects/BelorechenskTwoProject.vue'

const routes = [
      {
            path: '/',
            name: 'home',
            component: HomeView
      },
      {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },

      {
            path: '/projects',
            name: 'projects',
            children: [
                  {
                        // при совпадении пути с шаблоном /user/:id/profile
                        // в <router-view> компонента User будет показан UserProfile
                        path: 'BelorechenskProject',
                        name: 'BelorechenskProject',
                        component: BelorechenskProject
                  },
                  {
                        // при совпадении пути с шаблоном /user/:id/posts
                        // в <router-view> компонента User будет показан UserPosts
                        path: 'KrasnodarProject',
                        component: KrasnodarProject,
                        name: "KrasnodarProject"
                  },
                  {
                        // при совпадении пути с шаблоном /user/:id/profile
                        // в <router-view> компонента User будет показан UserProfile
                        path: 'BelorechenskOneProject',
                        name: 'BelorechenskOneProject',
                        component: BelorechenskOneProject
                  },
                  {
                        // при совпадении пути с шаблоном /user/:id/profile
                        // в <router-view> компонента User будет показан UserProfile
                        path: 'BelorechenskTwoProject',
                        name: 'BelorechenskTwoProject',
                        component: BelorechenskTwoProject
                  },

            ],
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/ProjectView.vue')
      }
]

const router = createRouter({
      history: createWebHashHistory(),
      routes,
      scrollBehavior() {
            document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      }
})

export default router
