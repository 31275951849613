<template>
  <footer id="footer">
    <a id="mail" target="_blank"
      href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=fomin_architects@gmail.com">mail</a>
    <a id="facebook" target="_blank" href="https://www.facebook.com/alexandr.fomin.5">facebook</a>



    <p id="name">© 2020 Fomin Architects</p>


  </footer>
</template>
<style>
#footer {
  padding: 10px 6.5vh 40px 6.5vh;
  position: absolute;
  max-width: 100%;
  bottom: 0;
  width: 100vw;
  box-sizing: border-box;
}

a {
  font-weight: bold;
  color: #2c3e50;
}

#mail {
  position: absolute;
  left: 6.5vh;
}

#facebook {
  position: absolute;
  width: min-content;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

#name {
  position: absolute;
  right: 6.5vh;
  font-weight: 400;
  color: black;
  font-size: 1.3vw;
  text-decoration: none;
  opacity: 0.5;
  margin: 0;
  font-family: 'Graphik';
}

@media (max-width: 600px) {
  #footer {
    padding: 20px;
    position: absolute;
    max-width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100vw;
    box-sizing: border-box;
  }

  #mail {
    position: initial;
  }

  #facebook {
    position: initial;
  }

  #name {
    position: initial;
    font-size: 16px;
  }

  p {
    font-size: 18px;
    opacity: 0.8;
  }
}
</style>