<template>
       <header id="header">
              <router-link id="left" to="/">FOMIN ARCHITECTS</router-link>
              <router-link id="center" to="/projects">works</router-link>
              <router-link id="right" to="/about">about</router-link>
       </header>
</template>
<style >
#header {
       padding: 25px 6.5vh 40px 6.5vh;
       position: absolute;
       max-width: 100%;
       top: 0;
       width: 100vw;


       box-sizing: border-box;
}

#center {
       position: absolute;
       width: min-content;
       margin-left: auto;
       margin-right: auto;
       left: 0;
       right: 0;
       text-align: center;
}

a {
       font-weight: bold;
       color: #2c3e50;
}

#left {
       position: absolute;
       left: 6.5vh;
}

#right {
       position: absolute;
       right: 6.5vh;
}
@media (max-width: 600px) {
       #header {
         padding: 20px;
         display: flex;
         justify-content: space-between;

       }  
       #center{
              position: initial;
       }
       #left{
              position: initial;
       }
       #right{
              position: initial;
       }
}
</style>