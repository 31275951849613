<template>
       <div class="home">
              <transition name="slide-fade" mode="out-in">
                     <div class="content"
                            :style="{ 'background-image': 'url(' + require(`../assets/${slide + '.jpg'}`) + ')' }"
                            v-bind:key="slide"></div>
              </transition>
              <div class="projects">
                     <div class="project">
                            <img class="project-img" :src="require('@/assets/projects/1.webp')" />
                            <router-link tag="div" to="/projects/KrasnodarProject" class="layer">
                                   <router-link to="/projects/KrasnodarProject">Krasnodar &nbsp;|&nbsp; 2020
                                   </router-link>
                            </router-link>
                            <router-link class="route-mobile" tag="div" to="/projects/KrasnodarProject">
                                   <router-link to="/projects/KrasnodarProject">Krasnodar &nbsp;|&nbsp; 2020
                                   </router-link>
                            </router-link>
                     </div>
                     <div class="project">
                            <img class="project-img" :src="require('@/assets/projects/2.webp')" />
                            <router-link tag="div" to="/projects/BelorechenskProject" class="layer">
                                   <router-link to="/projects/BelorechenskProject">Belorechensk &nbsp;|&nbsp; 2015
                                   </router-link>
                            </router-link>
                            <router-link class="route-mobile" tag="div" to="/projects/BelorechenskProject">
                                   <router-link to="/projects/BelorechenskProject">Belorechensk &nbsp;|&nbsp; 2015
                                   </router-link>
                            </router-link>
                     </div>
                     <div class="project">
                            <img class="project-img" :src="require('@/assets/projects/3.webp')" />

                            <router-link to="/projects/BelorechenskOneProject" tag="div" class="layer">
                                   <router-link to="/projects/BelorechenskOneProject">Belorechensk &nbsp;|&nbsp; 2013
                                   </router-link>
                            </router-link>
                            <router-link class="route-mobile" to="/projects/BelorechenskOneProject" tag="div">
                                   <router-link to="/projects/BelorechenskOneProject">Belorechensk &nbsp;|&nbsp; 2013
                                   </router-link>
                            </router-link>
                     </div>

                     <div class="project">
                            <img class="project-img" :src="require('@/assets/projects/4.webp')" />
                            <router-link tag="div" to="/projects/BelorechenskTwoProject" class="layer">
                                   <router-link to="/projects/BelorechenskTwoProject">Belorechensk &nbsp;|&nbsp; 2016
                                   </router-link>
                            </router-link>
                            <router-link class="route-mobile" tag="div" to="/projects/BelorechenskTwoProject">
                                   <router-link to="/projects/BelorechenskTwoProject">Belorechensk &nbsp;|&nbsp; 2016
                                   </router-link>
                            </router-link>
                     </div>

              </div>

       </div>
</template>

<script>
import TheFooter from "../components/TheFooter.vue";
export default {
       name: "HomeView",
       components: {
              TheFooter
       },
       data() {
              return {
                     sliders: [1, 2, 3, 4, 5],
                     slide: 1,
                     color: 'green',
                     time: 0
              }
       },
       mounted() {
              let self = this
              setInterval(function () {
                     if (self.slide < self.sliders.length) {
                            self.slide++
                     }
                     else if (self.slide === self.sliders.length) {
                            self.slide = self.sliders[0]
                     }
              }, 5000);
       },
       methods: {
       }

};
</script>
<style scoped>
.content {
       width: 100vw;
       height: 100vh;

       max-width: 100%;
       /* background: blue; */
       /* background-image: url("../assets/1.jpg"); */
       background-size: cover;

}

.route-mobile {
       display: none
}


.projects {
       width: 100vw;
       height: 100vh;
       background: #E0E2E1;
       ;
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr 1fr;
       box-sizing: border-box;
       padding: 5vh 6.5vh 10vh 6.5vh;
       max-width: 100%;
       grid-gap: 6.5vh;
}

.project {
       width: 100%;
       height: 100%;
       background-size: cover;
       position: relative;
       /* background-image: url("../assets/1.jpg"); */
}

.project:hover .layer {
       background: rgba(255, 255, 255, 0.6);
       display: flex;
       justify-content: center;
       align-items: center;
       opacity: 1 !important;
       transition-duration: 0.3s;
}

.layer {
       width: 100%;
       height: 100%;
       opacity: 0 !important;
       position: absolute;
       display: flex;
       top: 0;
       left: 0;
       height: 100%;
       height: 100%;

       justify-content: center;
       align-items: center;
       transition-duration: 0.3s;

}

.project-img {
       width: 100%;
       height: 100%;
       margin-bottom: 0;
}

.slide-fade-enter-active {
       transition: all 0.5s ease-out;


}

.slide-fade-leave-active {
       transition: all 0.5s cubic-bezier(1, 0, 0.8, 1);

}

.slide-fade-enter-from {
       opacity: 0;
}

.slide-fade-leave-to {

       opacity: 0;
}

@media (max-width: 600px) {
       .content {
              height: 400px;
       }

       .layer {
              width: 100%;
              height: 100%;
              opacity: 0 !important;
              display: flex;
              justify-content: center;
              height: 270px;
              align-items: center;
              position: absolute;
              top: 0;
              transition-duration: 0.5s;

       }

       .projects {
              width: 100%;
              height: auto;
              background: #E0E2E1;

              display: grid;
              grid-template-columns: 1fr;
              grid-template-rows: 1fr 1fr;
              box-sizing: border-box;
              padding: 20px 20px 100px 20px;
              max-width: 100%;
              grid-gap: 25px;
       }

       .project {
              height: auto;
              position: relative;
              height: auto;

       }

       .project-img {
              width: 100%;
              height: 270px;
              margin-bottom: 7px;
       }

       .project:hover .layer {
             opacity: 0!important;
       }

       .route-mobile {
              display: block
       }

}
</style>
