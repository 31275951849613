<template>
      <div style="z-index: 100;" :class="{ active: scrollpx < windowHeight - 230 }" class="left-menu">
            <div ><router-link  class="povorot-left" to="/">FOMIN ARCHITECTS</router-link></div>
      </div>

      <div style="z-index: 100;" :class="{ active: scrollpx < windowHeight -230 }" class="right-menu">
            <div class="povorot-right">
                  <router-link  to="/projects">works</router-link>
                  <router-link  to="/about">about</router-link>
            </div>

      </div>
      <div class="full-page-image"
            :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/1.jpg') + ')' }">
      </div>
      <div class="content padding-trl" style="display:flex;flex-direction:column">
            <div class="description">
                  <p>Private House</p>
                  <p>Belorechensk</p>
                  <p>2015</p>
            </div>
            <div class="image" :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/2.jpg') + ')' }">
            </div>
      </div>
      <div class="grid">
            <div class="project"
                  :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/3.jpg') + ')' }">
            </div>
            <div class="project"
                  :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/4.jpg') + ')' }">
            </div>
      </div>
      <div class="content padding-all">
            <div class="image" :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/5.jpg') + ')' }"></div>
      </div>
      <div class="full-page-image" :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/6.jpg') + ')' }">

      </div>
      <div class="grid">
            <div class="project"
                  :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/7.jpg') + ')' }">
            </div>
            <div class="project" :style="{ backgroundImage: 'url(' + require('@/assets/belorechensk_2015/8.jpg') + ')' }">
            </div>
      </div>
      <!-- <div id="govno" class="div-three" @mouseleave="upHere = false" @mouseover="upHere = true"> -->
      <!-- :style="{width:windowHeight + 'px'}" -->
      <!-- <div :style="{marginLeft:scrollpx + 'px'}" class="div" style="width:10px;height: 10px;background:yellow;"
                  v-if="upHere">fdf</div>
            <div class="images"></div>
            <div class="images"></div>
            <div class="images"></div>
            <div class="images"></div> -->
      <!-- <div class="div" style="background: black; ">cs</div>
            <div class="div" style="background: black; ">cs</div>
            <div class="div" style="background: black; ">cs</div> -->

      <!-- </div> -->


</template>
<script>
export default {
      data() {
            return {
                  scrollpx: 0,
                  windowHeight: window.innerHeight,
                  upHere: false
            };
      },
      mounted() {
            this.$nextTick(() => {
                  window.addEventListener('resize', this.onResize);
            })
      },

      beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
      },
      methods: {
            handleScroll(e) {
                  this.scrollpx = window.scrollY;
                  // var content = document.getElementById('govno');
                  // console.log(e.deltaY)




                  // // if (this.upHere === true) {
                  // console.log(window.scrollY)
                  // var x = window.scrollX;
                  // var y = window.scrollY;
                  // content.style.position = screenY
                  // // window.onscroll = function () { window.scrollTo(x, y); };
                  // // } else {
                  // //       console.log('no')
                  // // }
            },
            onResize() {
                  this.windowHeight = window.innerHeight
            },

      },
      created() {
            window.addEventListener('scroll', this.handleScroll);
      },
      destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
      }

}
</script>


<style scoped>
p {
      font-weight: 400;
      color: black;
      font-size: 1.3vw;
      text-decoration: none;
      opacity: 0.5;

      font-family: 'Graphik';
}

.left-menu {
      width: 6.5vh;
      height: 100vh;
      transition-duration: 0.3s;
      position: fixed;
      top: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
}

.right-menu {
      width: 6.5vh;
      height: 100vh;
      transition-duration: 0.3s;
      position: fixed;
      top: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
}
.project{
      background-size: cover;
}

.padding-all {
      padding: 6.5vh;
}

.padding-trl {
      padding: 6.5vh 6.5vh 0 6.5vh
}

.povorot-left {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      width: 100vh;

      line-height: 0;
      display: flex;
      justify-content: center;

}

.povorot-right {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
      width: 100vh;
      line-height: 0;
      display: flex;
      justify-content: space-evenly;
      position: absolute;
}

.content {
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      grid-gap: 50px;

      max-width: 100%;
      /* background: blue; */

      background-color: white;

}

.full-page-image {
      width: 100vw;
      height: 100vh;

      box-sizing: border-box;
      background-size: cover;
      max-width: 100%;
      /* background: blue; */


}

.images {
      /* background-image: url("../../assets/1.jpg"); */
      /* width: 100%;
      height: 100%; */
      /* background-size: cover; */
      /* transform: rotate(90deg); */
      background-color: blue;
      width: 500px;
      height: 500px;
      min-width: 500px;
}

.image {

      width: 100%;
      height: 100%;
      background-size: cover;

}

.description {
      display: flex;
      justify-content: space-between;
}

.active {
      opacity: 0;
      transition-duration: 0.3s;
}

.div-two {
      width: 100vw;
      height: 100vh;
      padding: 6.5vh 6.5vh;
      display: flex;
      flex-direction: column;
      background-color: #E0E2E1;
      max-width: 100%;
      box-sizing: border-box;
}

.div-four {
      width: 100vw;
      height: 100vh;
      padding: 6.5vh 6.5vh;
      background-color: #E0E2E1;
      max-width: 100%;
      box-sizing: border-box;

}

.grid {
      width: 100vw;
      height: 100vh;
      background: #ffffff;
      display: grid;
      grid-template-columns: 1.2fr 2fr;
      grid-template-rows: 1fr;
      box-sizing: border-box;
      padding: 10vh 6.5vh 10vh 6.5vh;
      max-width: 100%;
      grid-gap: 6.5vh;

}


.div-three {
      background: green;
      height: 60vh;
      /* width: 100vw; */
      padding: 6.5vh 6.5vh;
      overflow-x: scroll;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

      /* max-width: 100%; */




}

.aye {
      background-color: red;
      grid-gap: 40px;
      box-sizing: border-box;
      width: auto;
      /* transform: translatex(calc(50vw - 50%)) rotate(-90deg); */
      transform: rotate(-90deg);
      /* position: absolute; */
      height: auto;
      display: flex;
      flex-direction: column;
      /* 
      display:flex;
      flex-direction: column;
      grid-gap: 30px;
      justify-content: flex-start;
      align-items: center; */
      /* grid-template-rows: 70vh 70vh 70vh;
      grid-template-columns: 70vw; */
      /* overflow-x: scroll;
   
      transform-origin: center center */
}

.photo {

      /* background-image: url("../assets/fomin.jpg"); */
      background-size: cover;
}

P {
      font-size: 18PX;
}

.bio {
      text-align: left;
}

.project-fade-enter-active {
      transition: all 0.3s ease-out;
}

.projectfade-leave-active {
      transition: all 0.01s ease-out;
}

.project-fade-enter-from,
.project-fade-leave-to {

      opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
      transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
      opacity: 0
}
@media (max-width: 600px) {
      .left-menu {
            display: none;
      }

      .right-menu {
            display: none;
      }

      .padding-trl {
            padding: 20px
      }

      .grid {
            width: 100vw;
            height: auto;
            background: #ffffff;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            box-sizing: border-box;
            padding: 0 20px 20px 20px;
            max-width: 100%;
            grid-gap: 20px;

      }

      .project {
            height: 400px;
      }
      

      .image {

            width: 100%;
            height: 300px;
            background-size: cover;

      }

      .content {
            width: 100vw;
            height: auto;
            box-sizing: border-box;
            grid-gap: 20px;

            max-width: 100%;
            /* background: blue; */

            background-color: white;

      }

      .full-page-image {
            width: 100%;
            height: 300px ;
            margin-bottom: 20px;
            box-sizing: border-box;
            background-size: cover;
            max-width: 100%;
            /* background: blue; */


      }
      .padding-all {
      padding: 0 20px 20px 20px;
}
}
</style>